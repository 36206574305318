/** What's Weird: Homes **/

import React from "react";
import { gsap } from "gsap"
import { preloadImages } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { showText, hideText, positionText, resetAnimationDelay } from "../../helpers"
import Carousel from "../../components/media/carousel";
import SlideshowController from "../../components/media/slideshow-controller";

let globalState, data, preloadData, slideshowData, autoAdvanceInterval;

class WhatsWeirdPage3 extends React.Component {

  constructor(props) {
    super(props);

    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[3];
    preloadData = globalState.assets[4];

    slideshowData = [
      {
        file: globalState.videoUrl + data.slide1, 
        poster: globalState.cdnUrl + data.slide1Poster,
        fileType: 'video'
      },
      {
        file: globalState.cdnUrl + data.slide2,
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide3,
        fileType: 'image',
      },
      {
        file: globalState.cdnUrl + data.slide4, 
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide5, 
        fileType: 'image'
      },
      {
        file: globalState.videoUrl + data.slide6, 
        poster: globalState.cdnUrl + data.slide6Poster,
        fileType: 'video'
      },
      {
        file: globalState.cdnUrl + data.slide7, 
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide8, 
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide9, 
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide10, 
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide11, 
        fileType: 'image'
      },
      {
        file: globalState.cdnUrl + data.slide12, 
        fileType: 'image'
      }
    ]

    //refs
    this.fullscreenBg = null;
  }

  state = {
    textVisible: false,
    currentSlide: 1,
    autoAdvance: 7000
  }

  

  componentDidMount = () => {
    
    setTimeout(()=>{
      this.refs.carouselRef.playVideos();
    }, globalState.transitionDuration);

    //Set AutoAdvance
    this.setAutoAdvance();

    //position text
    positionText(this.refs.text1Ref, "right", "bottom", globalState.textPosition.rightBottom);
    

    //show text
    gsap.to(this.refs.text1Ref, 0.15, { autoAlpha: 1.0 });
    
    //add base track
    globalState.baseAudioRef.updateTrack(1, globalState.cdnUrl + data.soundfile1);

    //update volume of base track
    globalState.baseAudioRef.updateVolume(2, 100);

    
    //Preload assets from next page
    preloadImages([
      globalState.cdnUrl + preloadData.text1,
      globalState.cdnUrl + preloadData.text2,
      globalState.cdnUrl + preloadData.slide1,
      globalState.cdnUrl + preloadData.slide2,
      globalState.cdnUrl + preloadData.slide3,
      globalState.cdnUrl + preloadData.slide4,
      globalState.cdnUrl + preloadData.slide5,
      globalState.cdnUrl + preloadData.slide6,
      globalState.cdnUrl + preloadData.slide7,
      globalState.cdnUrl + preloadData.slide8,
      globalState.cdnUrl + preloadData.slide9,
    ], 
      this.preloadCallback);

    //update the carousel 
    this.refs.carouselRef.updateCurrentSlide(this.state.currentSlide);

  }

  preloadCallback = () =>{
    this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
  }

  componentDidUpdate() {
    this.refs.carouselRef.updateCurrentSlide(this.state.currentSlide);
  }
  

  componentWillUnmount() {
    
    //Kill any tweens
    gsap.killTweensOf(this.refs.text1Ref);
    
    //remove base track
    //globalState.baseAudioRef.updateTrack(1, false);

    //Clear AutoAdvanceInterval
    clearInterval(autoAdvanceInterval);
  }

  

  toggleTextDisplay = (event) => {

    //toggle text visibility
    this.setState({textVisible: !this.state.textVisible});

    //reset the animation delay
    resetAnimationDelay();

    if(this.state.textVisible){
      //show text
      showText(this.refs.text1Ref, "right", "bottom", globalState.textPosition.rightBottom);
      

    } else {
      //hide text
      hideText(this.refs.text1Ref, "right");
      
    }
  }

  isTransitioning = () => {
    //this.roadVideoRef.pauseVideo();
    this.refs.carouselRef.pauseVideos();
  }

  //Set AutoAdvance
  setAutoAdvance = () => {
    if (this.state.autoAdvance) {
      autoAdvanceInterval = setInterval(() => {
        let nextSlide = this.state.currentSlide + 1;

        if (nextSlide > slideshowData.length) {
          nextSlide = 1;
        }
        this.setState({ currentSlide: nextSlide });
      }, this.state.autoAdvance);
    }
  }

  //Stop AutoAdvance
  stopAutoAdvance = () => {
    this.setState({ autoAdvance: false });
    clearInterval(autoAdvanceInterval);
  }

  //Slideshow controls
  handleNextClick = () => {
    let nextSlide = this.state.currentSlide + 1;

    if (nextSlide > slideshowData.length) {
      nextSlide = 1;
    }

    this.setState({ currentSlide: nextSlide });
    this.stopAutoAdvance();
  }
  handleBackClick = () => {
    let nextSlide = this.state.currentSlide - 1;

    if (nextSlide < 1) {
      nextSlide = slideshowData.length;
    }

    this.setState({ currentSlide: nextSlide });
    this.stopAutoAdvance();
  }

  updateCurrentSlide = (num) => {

    for (let i = 1; i <= slideshowData.length; i++) {
      this.refs['slide' + i].classList.remove('show');
      if (i === this.state.currentSlide) {
        this.refs['slide' + i].classList.add('show');
      }
    }
  }

  render() {

    return (

      <>

        <link rel="prefetch" href="/whats-weird/4" />
        
        <PrevNext globalState={globalState} ref="prevNextRef" 
        nextLocation="/whats-weird/4" prevLocation="/whats-weird/2"
        isTransitioning={this.isTransitioning} />

        <div className="fullpage-wrapper">
          <SEO title="Pine Point - What's Weird" />


          <div className="text-wrapper">
            <div className="text text--right-bottom" ref="text1Ref">
              <input type="image" src={globalState.cdnUrl + data.text1} style={{ marginRight: "2vw", marginBottom: "70px" }} onClick={this.toggleTextDisplay} alt="Most of the homes were sold, and moved to nearby towns. Pine Pointers can tour places like nearby Hay River, or High Level, Alberta, and try to spot their old houses. An entire section of townhouses – ‘Browntown,’ named for its brown buildings – was moved to Hay River, where it was basically abandoned. It’s now that community’s eyesore, and still referred to as ‘Browntown.’ You can go to Fort Resolution and skate in the same ice rink I did when I first went to Pine Point. (Turns out the lobby is grey. It was never blue…)" />
              <SlideshowController xPos="300px" yPos="430px" handleNextClick={this.handleNextClick} handleBackClick={this.handleBackClick} state={this.state} totalSlides={slideshowData.length} />
            </div>
          </div>
          <Carousel ref="carouselRef" data={slideshowData} globalState={globalState} fullscreen={true} position={{x:0, y:0}} size="" state={this.state} />
          
        </div>

      </>
    );
  }


};

export default WhatsWeirdPage3
